import React, { useState, useEffect } from "react";
import { useCtaContext } from "../context/ctaContext";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";

const Bus = () => {
  const { handleBus } = useCtaContext();

  return (
    <AnimatePresence>
      <motion.div
        className="min-h-[100vh] w-full fixed top-0 flex flex-col justify-center items-center z-[100] "
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 2, duration: 1 }}
      >
        <div className="p-4">
          <div className="mx-auto relative flex justify-center">
            <div className="flex justify-between bg-gray-50 w-full h-full border-2 border-gray-50 rounded-[5px] mx-auto text-black  font-GilroyLight relative">
              <button
                onClick={() => handleBus(false)}
                className="absolute right-4 text-primary-color top-2 text-[20px] z-[99]"
              >
                <img
                  src="/icons/close-icon.svg"
                  alt="close"
                  className="w-[15px]"
                />
              </button>
              <img
                src="/images/event-update.jpg"
                alt=""
                className="w-max object-contain"
              />
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Bus;
