import React, { useEffect, useState } from "react";
import Header from "../header";
import Footer from "../footer";
import Cta from "../cta";
import { useCtaContext } from "../../context/ctaContext";
import Bus from "../bus";

const PageLayout = ({ children }) => {
  const { status, isBus } = useCtaContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-image relative min-h-[100vh] bg-[#FBF5DF]">
      {status && <Cta />}
      {isBus && <Bus />}
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default PageLayout;

//
