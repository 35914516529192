import React, { useState, useEffect } from "react";
import { useCtaContext } from "../context/ctaContext";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";

const Cta = () => {
  const { handleStatus } = useCtaContext();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSuccess = (msg) => toast.success(msg);
  const handleFailure = (msg) => toast.error(msg);

  const sendEmail = async (e) => {
    e.preventDefault();

    // Step 1: Save the contact to Brevo
    const contactData = {
      email: email,
      attributes: {
        FIRSTNAME: "", // You can add more attributes like FIRSTNAME, LASTNAME, etc.
      },
      listIds: [2], // Replace with the ID of the list you want to add the contact to
      updateEnabled: true, // If true, updates the contact if it already exists
    };

    try {
      setLoading(true);
      const contactResponse = await axios.post(
        "https://api.brevo.com/v3/contacts",
        contactData,
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_BREVO_KEY,
          },
        }
      );

      if (contactResponse.status === 201) {
        // Step 2: Send a welcome email to the user
        const emailData = {
          sender: {
            email: "amazingtechcommunity@gmail.com",
            name: "ATC Africa",
          },
          to: [{ email: email }],
          subject: "Welcome to Our Newsletter!",
          htmlContent: `
            <h1>Welcome!</h1>
            <p>Thank you for signing up for our newsletter. We're glad to have you with us.</p>
            <p>If you wish to unsubscribe, click <a href="[UNSUBSCRIBE_LINK]">here</a>.</p>
          `,
        };

        const emailResponse = await axios.post(
          "https://api.brevo.com/v3/smtp/email",
          emailData,
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": process.env.REACT_APP_BREVO_KEY,
            },
          }
        );

        if (emailResponse.status === 201) {
          handleSuccess("Subscribed successfully!");
        }
      } else {
        handleFailure("Failed to subscribe.");
      }
    } catch (error) {
      console.error(error);
      handleFailure("An error occurred.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <AnimatePresence>
      <motion.div
        className="min-h-[100vh] w-full fixed top-0 flex flex-col justify-center items-center z-[100] "
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="px-4">
          <div className="mx-auto relative w-full max-w-[800px] flex justify-center">
            {/* <div className="bg-black w-full max-w-[700px] h-full absolute top-3 translate-x-3 border-primary-color border-2 rounded-[5px]"></div> */}
            <div className="flex justify-between bg-gray-50 w-full max-w-[700px] h-full border-2 border-gray-50 rounded-[5px] mx-auto text-black  font-GilroyLight relative">
              <button
                onClick={() => handleStatus(false)}
                className="absolute right-4 text-primary-color top-2 text-[20px] z-[99]"
              >
                <img
                  src="/icons/close-icon.svg"
                  alt="close"
                  className="w-[15px]"
                />
              </button>
              <div className="w-full md:w-[65%] bg-[#FBF5DF] text-primary-color p-8 md:px-2 flex flex-col justify-center">
                <div className="max-w-[350px] mx-auto">
                  <h2 className="text-[25px]">Don't Miss Out!</h2>
                  <p className="text-[14px]">
                    Get Update on our upcoming biggest Students Fest
                  </p>
                  <form onSubmit={sendEmail} className="mt-4">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Email Address"
                      className="px-2 py-1 w-full border-2 rounded-[5px] border-primary-color outline-none focus-within:border-primary-color bg-transparent"
                    />
                    <button className="w-full border-2 bg-primary-color border-primary-color mt-4 rounded-[5px] px-2 py-1 text-white ">
                      {loading ? (
                        <img
                          src="/icons/tube-spinner.svg"
                          alt="Sending..."
                          className="w-[28px] block mx-auto"
                        />
                      ) : (
                        "Subscribe"
                      )}
                    </button>
                  </form>
                </div>
              </div>
              <div className="hidden md:flex w-[35%]">
                <img
                  src="/images/pop1.png"
                  alt=""
                  className="w-[50%] h-[250px] object-cover grayscale"
                />
                <img
                  src="/images/pop2.png"
                  alt=""
                  className="w-[50%] h-[250px] object-cover grayscale rounded-r-[5px]"
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Cta;
